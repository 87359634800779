@font-face {
  font-family: "Merriweather";
  src: url("/fonts/Merriweather-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Merriweather";
  src: url("/fonts/Merriweather-Bold.ttf");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Merriweather";
  src: url("/fonts/Merriweather-Italic.ttf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Merriweather";
  src: url("/fonts/Merriweather-BoldItalic.ttf");
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-Black.ttf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-Light.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-ExtraLight.ttf");
  font-style: normal;
  font-weight: 200;
}